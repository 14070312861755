import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';

import makeStyles from '@mui/styles/makeStyles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/CheckCircle';

import { AppBar, Box, Button, CircularProgress, Dialog, IconButton, Slide, TableContainer, Toolbar, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';

import { dmsUrl } from '@ekultur/ui-schema-components';

import { setFinderMode, setExportEnabled } from '../redux/actions/layout';
import { requestFolderObjects, requestWikimediaExport, requestWikimediaExportStatus } from '../redux/actions/folders';
import { ProgressIndicator } from '../common/ProgressIndicator';
import { WithProgressOverlay } from '../common/WithProgressOverlay';
import { Error } from '../common/Error';
import { formatDate } from '../common/Date';
import { folderExportSchema } from './schema/folderExportSchema';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        overflowX: 'auto',
        padding: 10
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    wrapper: {
        display: 'flex',
        '& > div': {
            position: 'relative',
            '& a': {
                marginTop: '1rem',
                display: 'block'
            }
        }
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        '&.failed': {
            color: 'red'
        },
        '&.finished': {
            color: 'green'
        }
    }
}));

const isLastPage = (rowCount, paging) =>{
    return Math.floor((rowCount - 1) / paging.rows) === paging.page;
}

const displaysRowsText = ({ from, to, count }) => {
    const calculatedTo = to === -1 ? count : to;
    return `${from}-${calculatedTo} av ${count}`;
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export const FolderObjects = () => {
    const { t, ready } = useTranslation('dm.wikimedia', { useSuspense: false }),
        dispatch = useDispatch(),
        pageRef = useRef(),
        { folderId } = useParams(),
        [paging, setPaging] = useState({ rows: 10, page: 0 }),
        { features } = useSelector(state => state.features),
        { userData } = useSelector(state => state.user),
        { ownerUniqueId } = useSelector(state => state.app),
        { dimu_unique_id, rows, rowCount, fetching, error } = useSelector(state => state.folderObjects),
        title = useSelector(state => state.activeFolderTitle),
        { status } = useSelector(state => state.wikimediaExportStatus),
        { fetching: exportInProgress } = useSelector(state => state.wikimediaExport),
        [isEditDialogOpen, setIsEditDialogOpen] = useState(false),
        classes = useStyles();

    const changePageHandler = (newPage) => {
        setPaging({
            ...paging,
            page: newPage,
        });
    };

    const changeRowsPerPageHandler = (event) => {
        setPaging({
            rows: parseInt(event.target.value, 10),
            page: 0,
        });
    };

    const onFormChanged = form => {
        pageRef.current = form.formData;
    };

    const onSubmit = () => {
        dispatch(requestWikimediaExport(ownerUniqueId, dimu_unique_id, rowCount, pageRef.current.institution));
        setIsEditDialogOpen(false);
    };

    const editDialogClose = () => {
        setIsEditDialogOpen(false);
    };

    useEffect(() => {
        dispatch(setFinderMode('folders'));
        dispatch(setExportEnabled(false));
    }, [dispatch]);

    useEffect(() => {
        if (userData.uniqueId && folderId) {
            dispatch(requestFolderObjects(userData.uniqueId, folderId, paging.page * paging.rows, paging.rows));
        }
    }, [dispatch, userData.uniqueId, paging.page, paging.rows, folderId]);

    useEffect(() => {
        if (ownerUniqueId && folderId && [null, 'in progress'].includes(status)) {
            dispatch(requestWikimediaExportStatus(ownerUniqueId, folderId, status));
        }
    }, [dispatch, ownerUniqueId, folderId, status]);

    if (!ready) {
        return <ProgressIndicator />;
    }

    if (error) {
        return <Error />;
    }

    return (
        <WithProgressOverlay loading={fetching}>
            <h1>{title}</h1>
            <div className={classes.wrapper}>
                <div>
                    <Button variant="contained" color="primary" disabled={!features || !features.includes('WikimediaExport') || ![null, 'Export not initiated'].includes(status) || exportInProgress} onClick={() => setIsEditDialogOpen(true)}>{t('export_to_wikimedia', 'Eksporter bilder til Wikimedia Commons')}</Button>
                    {(status === 'in progress' || exportInProgress) && <CircularProgress size={24} className={classes.buttonProgress} />}
                    {status === 'failed' && <ErrorIcon size={24} className={`${classes.buttonProgress} failed`} />}
                    {status === 'finished' && <CheckIcon size={24} className={`${classes.buttonProgress} finished`} />}
                    {(!features || !features.includes('WikimediaExport')) && (
                        <Link
                            href={t('about_wm_export_link', 'https://dok.digitaltmuseum.org/eksportere-bilder-til-wikimedia')}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {t('about_wm_export_text', 'Wikimediaeksport er under utvikling. Les mer her')}
                        </Link>
                    )}
                </div></div>
            <h2>{rowCount} objekter</h2>
            <TableContainer className={classes.root}>
                <Table>
                    <TableHead className={classes.head}>
                        <TableRow>
                            <TableCell key="emptyCell" />
                            {['title', 'publishedAt', 'updatedAt'].map((header) => (
                                <TableCell key={header}>
                                    {t(header)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.dimu_unique_id}>
                                <TableCell>
                                    {row.image_id && (
                                        <Link
                                            href={`https://digitaltmuseum.org/${row.dimu_unique_id}/${row.slug}`}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            <img alt={row.image_id} src={`${dmsUrl({ image_id: row.image_id })}?dimension=57x57-crop`} />
                                        </Link>
                                    )}
                                </TableCell>
                                {['title', 'publishedAt', 'updatedAt'].map((field, index) => (
                                    <TableCell key={index}>
                                        {['createdAt', 'updatedAt'].includes(field)
                                            ? formatDate(row[field])
                                            : row[field]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rowCount}
                rowsPerPage={paging.rows}
                page={paging.page}
                onPageChange={(event, newPage) => changePageHandler(newPage)}
                onRowsPerPageChange={changeRowsPerPageHandler}
                backIconButtonProps={{ disabled: fetching || paging.page === 0 }}
                nextIconButtonProps={{ disabled: fetching || isLastPage(rowCount, paging) }}
                SelectProps={{ disabled: fetching }}
                labelRowsPerPage="Rader per side:"
                labelDisplayedRows={displaysRowsText}
            />
            <Dialog
                open={isEditDialogOpen}
                onClose={editDialogClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {t('export_to_wikimedia', 'Eksporter bilder til Wikimedia Commons')}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={editDialogClose}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box m={3}>
                    <Typography variant="h6">
                        {t('wm_institution_name_desc', 'Før eksport må institusjonen ha en side på Wikimedia. Legg inn institusjonens navn nøyaktig slik det er på Wikimedia under. Det må være identisk, inkludert små/store bokstaver for at lenker fra eksporterte bilder skal fungere!')}
                    </Typography>
                    <Form
                        validator={validator}
                        schema={folderExportSchema}
                        onChange={onFormChanged}
                    >
                        <Button type="submit" variant="contained" color="primary" className={classes.button}
                            onClick={onSubmit}>{t('submit', 'Start eksport')}</Button>
                    </Form>
                </Box>
            </Dialog>

        </WithProgressOverlay>
    );
};
