import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid2 as Grid } from '@mui/material';

import { setFinderMode, setExportEnabled } from '../redux/actions/layout';

import {
    StatisticsReportMetrics,
    StatisticsReportLocation,
    StatisticsReportAcquisition,
    StatisticsReportObjectTypes
} from '../Statistics/plausible'

export const Dashboard = () => {
    const dispatch = useDispatch(),
        { currentDetail } = useSelector(state => state.layout);

    useEffect(() => {
        dispatch(setFinderMode('dashboard', currentDetail));
        dispatch(setExportEnabled(false));
    }, [dispatch, currentDetail]);

    return (
        <Grid container sx={{ width: 'auto' }} spacing={1}>
            <Grid item sm={12} md={12} lg={7}>
                <StatisticsReportMetrics viewType='dashboard' />
            </Grid>
            <Grid item sm={12} md={12} lg={5}>
                <StatisticsReportObjectTypes viewType='dashboard' />
            </Grid>
            <Grid item sm={12} md={12} lg={7}>
                <StatisticsReportAcquisition viewType='dashboard' />
            </Grid>
            <Grid item sm={12} md={12} lg={5}>
                <StatisticsReportLocation viewType='dashboard' />
            </Grid>
        </Grid>
    );
};
