import { Trans } from 'react-i18next';

import {
    ArrayFieldTemplate, NoWrapperTemplate, NullTemplate, UniqueKeyFieldTemplate,
    SettingsTemplate, SectionTemplate, BlockArrayFieldTemplate, BlockTemplate,
    DmArrayFieldTemplate
} from '@ekultur/ui-schema-components';

import { MarkdownWidget } from '../widgets/MarkdownWidget';

import { buildMediaListUiSchema, ContentTemplate, ReadOnlyValueFieldTemplate, SearchTemplate, ArticleSettingsArrayFieldTemplate, ArticleSettingsItemTemplate, AddressTemplate } from '../uiSchema';
import { OwnerWidget } from '../widgets/OwnerWidget';
import KnavFieldTemplate from './fieldTemplates/KnavFieldTemplate';


const KnavUpdateMessage = () => {
    return 'Feltet oppdateres fra KulturNav ved å klikke oppdater-ikonet over. Du kan overstyre ved å legge inn egendefinert verdi under.';
};

export const buildUiSchema = () => {
    const mediaListUiSchema = buildMediaListUiSchema();

    return {
        'ui:submitButtonOptions': { norender: true },
        content: {
            'ui:ObjectFieldTemplate': ContentTemplate,
            page_type: {
                'ui:widget': 'hidden'
            },
            tags: {
                'ui:ArrayFieldTemplate': ArticleSettingsArrayFieldTemplate,
                items: {
                    'ui:ObjectFieldTemplate': ArticleSettingsItemTemplate,
                },
                'ui:options': {
                    addButtonLabel: <Trans ns="dm.article" i18nKey="add_topic">Legg til nøkkelord</Trans>,
                    orderable: false
                }
            },
            sections: {
                'ui:ArrayFieldTemplate': ArrayFieldTemplate,
                items: {
                    'ui:ObjectFieldTemplate': SectionTemplate,
                    _editor: {
                        'ui:ObjectFieldTemplate': NoWrapperTemplate,
                        _collapse: {
                            'ui:widget': 'hidden'
                        }
                    },
                    blocks: {
                        'ui:ArrayFieldTemplate': BlockArrayFieldTemplate,
                        items: {
                            'ui:ObjectFieldTemplate': BlockTemplate,
                            _editor: {
                                'ui:ObjectFieldTemplate': NoWrapperTemplate,
                                _collapse: {
                                    'ui:widget': 'hidden'
                                }
                            },
                            _unique_key: { 'ui:FieldTemplate': UniqueKeyFieldTemplate },
                            settings: { 'ui:ObjectFieldTemplate': SettingsTemplate },
                            type: { 'ui:FieldTemplate': NullTemplate },
                            name: {
                                'ui:help': KnavUpdateMessage(),
                                'ui:FieldTemplate': KnavFieldTemplate,
                                'ui:KnavFieldName': 'name'
                            },
                            ingress: {
                                'ui:help': KnavUpdateMessage(),
                                'ui:FieldTemplate': KnavFieldTemplate,
                                'ui:KnavFieldName': 'description'
                            },
                            address: {
                                'ui:ObjectFieldTemplate': NoWrapperTemplate,
                                'ui:help': KnavUpdateMessage(),
                                'ui:FieldTemplate': KnavFieldTemplate,
                                'ui:KnavFieldName': 'address'
                            },
                            customAddress: {
                                'ui:ObjectFieldTemplate': AddressTemplate
                            },
                            coordinates: {
                                'ui:ObjectFieldTemplate': NoWrapperTemplate,
                                'ui:help': KnavUpdateMessage(),
                                'ui:FieldTemplate': KnavFieldTemplate,
                                'ui:KnavFieldName': 'coordinates'
                            },
                            url: {
                                'ui:help': KnavUpdateMessage(),
                                'ui:FieldTemplate': KnavFieldTemplate,
                                'ui:KnavFieldName': 'url'
                            },
                            uu_declaration: {
                                'ui:ObjectFieldTemplate': NoWrapperTemplate
                            },
                            bodytext: {
                                'ui:widget': MarkdownWidget
                            },
                            resources: {
                                'ui:ObjectFieldTemplate': NoWrapperTemplate,
                                mediaList: mediaListUiSchema
                            }
                        },
                        'ui:options': {
                            addable: false,
                            removable: false,
                            orderable: false
                        }
                    },
                    searches: {
                        'ui:ArrayFieldTemplate': BlockArrayFieldTemplate,
                        items: {
                            'ui:ObjectFieldTemplate': BlockTemplate,
                            _editor: {
                                'ui:ObjectFieldTemplate': NoWrapperTemplate,
                                _collapse: {
                                    'ui:widget': 'hidden'
                                }
                            },
                            _unique_key: { 'ui:FieldTemplate': UniqueKeyFieldTemplate },
                            settings: { 'ui:ObjectFieldTemplate': SettingsTemplate },
                            type: { 'ui:FieldTemplate': NullTemplate },
                            titleHidden: { 'ui:disabled': true },
                            resources: {
                                'ui:ObjectFieldTemplate': NoWrapperTemplate,
                                entryList: {
                                    'ui:ArrayFieldTemplate': DmArrayFieldTemplate,
                                    'ui:options': {
                                        addButtonLabel: <Trans ns="dm.article" i18nKey="add_object">Finn objekt</Trans>,
                                        addSearchLabel: <Trans ns="dm.article" i18nKey="add_search">Legg til søk</Trans>
                                    },
                                    items: {
                                        'ui:ObjectFieldTemplate': SearchTemplate,
                                        objectId: { 'ui:FieldTemplate': ReadOnlyValueFieldTemplate },
                                        artifactType: { 'ui:FieldTemplate': ReadOnlyValueFieldTemplate },
                                        filter: {
                                            owner: { 'ui:widget': OwnerWidget }
                                        },
                                        settings: {
                                            'ui:ObjectFieldTemplate': SettingsTemplate
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                'ui:options': {
                    addable: false,
                    removable: false,
                    orderable: false
                }
            }
        }
    };
};
