import { combineReducers } from 'redux';

import { app } from './reducers/app';
import statistics from './reducers/plausible/statistics';
import { mashups } from './reducers/mashup';
import layout from './reducers/layout';
import articleList from './reducers/articleList';
import article from './reducers/article';
import { folderList, folderIds, folderObjects, wikimediaExport, wikimediaExportStatus, activeFolderTitle } from './reducers/folders';
import features from './reducers/features';
import { userAccess, user } from './reducers/user';
import { resources, dmSearch, previewDmSearch } from './reducers/media';
import { slugs } from './reducers/slugs';

const rootReducer = () => combineReducers({
    app,
    layout,
    statistics,
    mashups,
    articleList,
    article,
    folderList,
    folderIds,
    folderObjects,
    activeFolderTitle,
    wikimediaExport,
    wikimediaExportStatus,
    features,
    userAccess,
    user,
    resources,
    dmSearch,
    previewDmSearch,
    slugs
 });

export default rootReducer;
