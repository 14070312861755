// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    padding: .3em 1em;
    gap: .2em;

    background: #FAF8F2;
    border: 2px solid #181821;
    border-radius: 1.5em;

    text-decoration: none;
    cursor: pointer;
}

.card-button:hover {
    background: #FEFCF6;
}

.card-button-label {
    font-size: inherit;
    font-style: normal;
    font-weight: 700;

    color: #1B1821;

    white-space: nowrap;
}

.card-button-svg {
    width: 24px;
    height: 24px;
    flex: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Button/button.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,SAAS;;IAET,mBAAmB;IACnB,yBAAyB;IACzB,oBAAoB;;IAEpB,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;;IAEhB,cAAc;;IAEd,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,OAAO;AACX","sourcesContent":[".card-button {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    position: relative;\n    width: fit-content;\n    margin: 0 auto;\n    padding: .3em 1em;\n    gap: .2em;\n\n    background: #FAF8F2;\n    border: 2px solid #181821;\n    border-radius: 1.5em;\n\n    text-decoration: none;\n    cursor: pointer;\n}\n\n.card-button:hover {\n    background: #FEFCF6;\n}\n\n.card-button-label {\n    font-size: inherit;\n    font-style: normal;\n    font-weight: 700;\n\n    color: #1B1821;\n\n    white-space: nowrap;\n}\n\n.card-button-svg {\n    width: 24px;\n    height: 24px;\n    flex: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
