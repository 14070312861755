import React from 'react';
import { Trans } from 'react-i18next';

export const dmObject = {
    type: 'object',
    required: ['type'],
    properties: {
        type: { enum: ['DM'] },
        url: { type: 'string' },
        artifactType: {
            type: 'string',
            title: null,
            readOnly: true
        },
        objectId: {
            type: 'string',
            title: null,
            readOnly: true
        },
        description: { type: 'string' },
        image_id: { type: 'string' },
        source: { type: 'string' },
        title: {
            type: 'string',
            title: (
                <Trans ns="dm.article" i18nKey="image_text">
                    Bildetekst
                </Trans>
            )
        },
        image_count: { type: 'integer' },
        identifier: { type: 'string' },
        producer: {
            type: 'string',
            title: (
                <Trans ns="dm.article" i18nKey="image_credit">
                    Fotograf
                </Trans>
            )
        },
        owner_name: { type: 'string' },
        local: {
            type: 'object',
            title: '',
            properties: {
                credit: {
                    type: 'string',
                    title: (
                        <Trans ns="dm.article" i18nKey="image_credit">
                            Fotograf
                        </Trans>
                    )
                },
                description: {
                    type: 'string',
                    title: (
                        <Trans ns="dm.article" i18nKey="image_text">
                            Bildetekst
                        </Trans>
                    )
                }
            }
        },
        /* licenses: {
            type: 'array',
            maxItems: 1,
            minItems: 1,
            title: null,
            items: {
                type: 'string',
                enum: [
                    'CC pdm',
                    'zero',
                    'CC by',
                    'CC by-sa'
                ],
                enumNames: [
                    <Trans ns="dm.article" i18nKey="license_PDM">Falt i det fri (PDM)</Trans>,
                    <Trans ns="dm.article" i18nKey="license_ZERO">Ingen rettigheter forbeholdt (CC0)</Trans>,
                    <Trans ns="dm.article" i18nKey="license_BY">Kun navngivelse (BY)</Trans>,
                    <Trans ns="dm.article" i18nKey="license_BY-SA">Navngivelse, del på samme vilkår (CC BY-SA)</Trans>
                ]
            }
        }, */
        settings: {
            type: 'object',
            title: null,
            properties: {
                size: {
                    type: 'string',
                    enum: ['small', 'medium', 'large'],
                    enumNames: [
                        <Trans ns="dm.article" i18nKey="search-card_small">
                            Liten
                        </Trans>,
                        <Trans ns="dm.article" i18nKey="search-card_medium">
                            Medium
                        </Trans>,
                        <Trans ns="dm.article" i18nKey="search-card_large">
                            Stor
                        </Trans>
                    ],
                    default: 'medium'
                }
            }
        },
        media: {
            type: 'object',
            properties: {
                width: { type: 'integer' },
                height: { type: 'integer' },
                ratio: { type: 'integer' }
            }
        }
    }
};
