import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import {Button} from '@mui/material';
import SaveIcon from '@mui/icons-material/SaveAlt';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

import { requestExport } from '../redux/actions/plausible/statistics';


const useStyles = makeStyles(theme => ({
    wrapper: {
        flex: '1 0 auto',
        zIndex: 2
    },
    export: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    success: {
        color: green[500]
    },
    error: {
        color: 'red'
    }
}));

export const ButtonExport = ({ title }) => {
    const dispatch = useDispatch(),
        classes = useStyles(),
        location = useLocation(),
        [success, setSuccess] = React.useState(false),
        [loading, setLoading] = React.useState(false),
        { reports } = useSelector(state => state.statistics),
        { currentDetail } = useSelector(state => state.layout);

    useEffect(() => {
        setLoading(reports.export.fetching);
        setSuccess(reports.export.fetching !== null && !reports.export.fetching);
    }, [reports.export.fetching]);

    useEffect(() => {
        setSuccess(false);
    }, [location.pathname, currentDetail]);

    const handleButtonClick = () => {
        if (!loading) {
            dispatch(requestExport());
        }
    };

    const setStartIcon = () => {
        if (reports.export.error) {
            return <ErrorIcon className={classes.error} />;
        } else if (success) {
            return <CheckIcon className={classes.success} />;
        }

        return <SaveIcon />;
    };

    return (
        <div className={`${classes.wrapper} exportButton`}>
            <Button
                startIcon={setStartIcon()}
                className={classes.export}
                disabled={loading}
                onClick={handleButtonClick}
            >
                {title}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    )
};
