import React from 'react';
import { Trans } from 'react-i18next';

export const schoolObject = {
    type: 'object',
    required: ['type'],
    properties: {
        type: { enum: ['school'] },
        url: { type: 'string' },
        artifactType: {
            type: 'string',
            title: null,
            readOnly: true
        },
        objectId: {
            type: 'string',
            title: null,
            readOnly: true
        },
        description: { type: 'string' },
        image_id: { type: 'string' },
        source: { type: 'string' },
        title: {
            type: 'string',
            title: (
                <Trans ns="dm.article" i18nKey="image_text">
                    Bildetekst
                </Trans>
            )
        },
        image_count: { type: 'integer' },
        identifier: { type: 'string' },
        producer: {
            type: 'string',
            title: (
                <Trans ns="dm.article" i18nKey="image_credit">
                    Fotograf
                </Trans>
            )
        },
        owner_name: { type: 'string' },
        local: {
            type: 'object',
            title: '',
            properties: {
                credit: {
                    type: 'string',
                    title: (
                        <Trans ns="dm.article" i18nKey="image_credit">
                            Fotograf
                        </Trans>
                    )
                },
                description: {
                    type: 'string',
                    title: (
                        <Trans ns="dm.article" i18nKey="image_text">
                            Bildetekst
                        </Trans>
                    )
                }
            }
        },

        settings: {
            type: 'object',
            title: null,
            properties: {
                size: {
                    type: 'string',
                    enum: ['small', 'medium', 'large'],
                    enumNames: [
                        <Trans ns="dm.article" i18nKey="search-card_small">
                            Liten
                        </Trans>,
                        <Trans ns="dm.article" i18nKey="search-card_medium">
                            Medium
                        </Trans>,
                        <Trans ns="dm.article" i18nKey="search-card_large">
                            Stor
                        </Trans>
                    ],
                    default: 'medium'
                }
            }
        },
        media: {
            type: 'object',
            properties: {
                width: { type: 'integer' },
                height: { type: 'integer' },
                ratio: { type: 'integer' }
            }
        }
    }
};
