import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Link from '@mui/material/Link';
import { TableContainer } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';

import { requestDownloadStatistics, exportDownloadStatistics } from '../../redux/actions/plausible/statistics';
import { setTimePeriod } from '../../redux/functions/statistics';
import { getValueFromQueryString } from '../../redux/functions/query';
import { setFinderMode, setExportEnabled } from '../../redux/actions/layout';

import { ProgressIndicator } from '../../common/ProgressIndicator';
import { WithProgressOverlay } from '../../common/WithProgressOverlay';

const useStyles = makeStyles(({ palette }) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        padding: 10,
        boxSizing: 'border-box'
    },
    totalRow: {
        background: palette.action.selected
    }
}));

export const StatisticsReportDownloads = ({ viewType }) => {
    //  paging is disabled until API supports it
    const dispatch = useDispatch(),
        classes = useStyles(),
        { t, ready } = useTranslation('plausibleAnalytics', { useSuspense: false }),
        location = useLocation(),
        pathArr = location.pathname.split('/'),
        reportType = pathArr[2] || 'downloads',
        [paging, setPaging] = useState({ rows: 10, page: 0 }),
        period = getValueFromQueryString('period'),
        { reports } = useSelector(state => state.statistics),
        downloads = reports && reports[viewType][reportType],
        { requested: exportRequested, fetching } = reports.export,
        { ownerUniqueId } = useSelector(state => state.app),
        { filters, currentDetail } = useSelector(state => state.layout),
        timePeriod = setTimePeriod(filters, currentDetail, period, false, true);


    useEffect(() => {
        dispatch(requestDownloadStatistics(ownerUniqueId, timePeriod.start, timePeriod.end, paging.rows, paging.page+1));
    }, [dispatch, ownerUniqueId, timePeriod.start, timePeriod.end, paging.rows, paging.page]);

    useEffect(() => {
        if (exportRequested && !fetching) {
            dispatch(exportDownloadStatistics(ownerUniqueId, timePeriod.timePeriod, timePeriod.start, timePeriod.end));
        }
    }, [dispatch, ownerUniqueId, timePeriod.timePeriod, timePeriod.value, timePeriod.start, timePeriod.end, exportRequested, fetching]);

    useEffect(() => {
        dispatch(setFinderMode('downloads', period));
        dispatch(setExportEnabled(true));
    }, [dispatch, period]);

    const changePageHandler = newPage => {
        setPaging({
            ...paging,
            page: newPage
        })
    };

    const changeRowsPerPageHandler = event => {
        setPaging({
            rows: parseInt(event.target.value, 10),
            page: 0
        })
    };


    if (!ready) {
        return <ProgressIndicator />
    }

    const isLastPage = () => (downloads.rowCount < paging.rows);

    const displaysRowsText = ({from, to}) => {
        if (downloads.fetching) {
            return null;
        }

        const calculatedTo = to > from + downloads.rowCount ? from + downloads.rowCount : to;
        return `${from}-${calculatedTo}`;
    };

    return (
        <WithProgressOverlay loading={downloads.fetching}>
            <Paper className={classes.root} elevation={3}>
                <TableContainer>
                    <Table>
                        <TableHead className={classes.head}>
                            <TableRow>
                                <TableCell key='emptyCell' />
                                <TableCell align='right' key='downloadsCell'>
                                    {t("Downloads")}
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.totalRow}>
                                <TableCell key='headerCell'>
                                    Totalt:
                                </TableCell>
                                <TableCell align='right' key='downloadsCell'>
                                    {downloads.total.events || 0}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {downloads.response.results.map(row => (
                                <TableRow key={row.dimuCode}>
                                    <TableCell component='th' scope='row' key='headerCell'>
                                        <Link
                                            href={row.link}
                                            rel='noreferrer'
                                            target='_blank'
                                            color='inherit'
                                        >
                                            {row.identificationNumber ? row.identificationNumber : row.dimuCode}
                                        </Link>
                                    </TableCell>
                                    <TableCell align='right' key='downloadsCell'>
                                        {row.events}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component='div'
                    count={-1}
                    rowsPerPage={paging.rows}
                    page={paging.page}
                    onPageChange={(event, newPage) => changePageHandler(newPage)}
                    onRowsPerPageChange={changeRowsPerPageHandler}
                    backIconButtonProps={{ disabled: downloads.fetching || paging.page === 0 }}
                    nextIconButtonProps={{ disabled: downloads.fetching || isLastPage() }}
                    SelectProps={{ disabled: isLastPage() }}
                    labelRowsPerPage='Rader per side:'
                    labelDisplayedRows={displaysRowsText}
                />
            </Paper>
        </WithProgressOverlay>
    );
};


