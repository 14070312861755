import React, { Fragment, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { List, Paper, Grid, Box, Button, Chip, Input, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import DoneIcon from '@mui/icons-material/Done';
import { Trans } from 'react-i18next';

import { Accordion } from '@ekultur/accordion';

import {
    ArrayFieldTemplate,
    ArrayItemTemplate,
    NoWrapperTemplate,
    NullTemplate,
    UniqueKeyFieldTemplate,
    SettingsTemplate,
    SectionTemplate,
    BlockArrayFieldTemplate,
    BlockTemplate,
    MediaArrayFieldTemplate,
    ImageTemplate,
    ImageItemTemplate,
    SvgThumbnailFieldTemplate,
    DmArrayFieldTemplate,
    SchoolArrayFieldTemplate
} from '@ekultur/ui-schema-components';

import { DmSearchItemTemplate } from './searchItem/SearchItem';

import { useMashupStyles } from '../../Mashup/mashupStyles';

import { OwnerWidget } from './widgets/OwnerWidget';
import { PlaceQueryWidget } from './widgets/PlaceQueryWidget';
import { MarkdownWidget } from './widgets/MarkdownWidget';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
    articleSettings: {
        padding: spacing(1)
    },
    mediaItem: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'wrap',
        '& .MuiTextField-root': {
            width: '100%'
        },
        '& .fieldWrapper': {
            width: 'calc(100% - 120px)',
            '& label + .MuiInput-formControl': {
                marginTop: spacing(1.5)
            },
            '& .anchor_desc': {
                flex: '0 0 100%',
                marginTop: spacing(1)
            },
            '& .link_btn': {
                marginTop: spacing(1),
                marginRight: 'auto',
                '& + .ok': {
                    marginTop: spacing(1),
                    marginRight: '100%'
                }
            }
        },
        '& .image--preview': {
            position: 'relative',
            minWidth: spacing(9),
            marginRight: '.5em',
            placeContent: 'center',
            background: '#423f3c',
            '&.svg': {
                background: 'white'
            },
            '& img': {
                position: 'absolute',
                top: 0,
                bottom: 0,
                maxWidth: '100%',
                maxHeight: '100%',
                width: 'auto',
                height: 'auto',
                margin: 'auto'
            }
        }
    },
    searchItem: {
        display: 'flex',
        position: 'relative',
        width: 'calc(100%-28px)',
        '& h5': {
            marginTop: spacing(1),
            fontSize: '1rem',
            fontWeight: 400
        },
        '& .titleWrapper': {
            display: 'flex',
            flex: '1 1 auto',
            maxWidth: '100%',
            alignItems: 'center',
            cursor: 'pointer',
            '& h5': {
                margin: spacing(0.5)
            }
        },
        '&.greyBox': {
            border: '1px solid #ddd',
            padding: '8px',
            marginTop: '8px',
            minHeight: '44px',
            backgroundColor: '#f5f5f5'
        }
    },
    tagWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > div': {
            marginTop: spacing(1),
            marginRight: spacing(1)
        },
        '& .MuiChip-label': {
            overflow: 'visible'
        }
    },
    chip: props => ({
        ...props.typeChip,
        marginBottom: spacing(0.5)
    })
}));

const NoWrapperFieldTemplate = props => {
    return props.children;
};

export const ContentTemplate = props => {
    const settingObjects = ['local_belonging', 'tags'],
        settingsElmts = props.properties.filter(el => settingObjects.includes(el.name));

    return (
        <List disablePadding={true}>
            {!!settingsElmts.length && ArticleSettingsTemplate(settingsElmts)}
            {props.properties.map(el => {
                if (!settingObjects.includes(el.name)) {
                    return el.content;
                }
                return null;
            })}
        </List>
    );
};

const LinkArrayFieldTemplate = props => {
    const { items, schema, onAddClick } = props;

    const onAdd = type => {
        schema.items.properties.type.default = type;
        onAddClick();
    };

    return (
        <>
            <h5 className="headerBlock_links_title">
                <Trans ns="dm.article" i18nKey="owner.headerBlock.links_title">
                    Lenker
                </Trans>
            </h5>
            {items.map(el => ArrayItemTemplate(el))}
            {props.canAdd && (
                <>
                    <Button variant="contained" className="array-item-add" onClick={() => onAdd('article__bodytext')} disabled={props.disabled || props.readonly}>
                        <AddIcon />
                        {props.uiSchema['ui:options'] && props.uiSchema['ui:options'].addInternalLinkButtonLabel}
                    </Button>
                    <Button variant="contained" className="array-item-add" onClick={() => onAdd('link')} disabled={props.disabled || props.readonly}>
                        <AddIcon />
                        {props.uiSchema['ui:options'] && props.uiSchema['ui:options'].addLinkButtonLabel}
                    </Button>
                </>
            )}
        </>
    );
};

const LinkItemTemplate = props => {
    const [expanded, setExpanded] = useState(false),
        classes = useStyles();

    return (
        <div key={props.index} className={`${expanded ? classes.mediaItem : classes.searchItem} greyBox`}>
            {!expanded && (
                <div className="titleWrapper" onClick={() => setExpanded(true)}>
                    <LinkIcon />
                    {props.properties.map(el => {
                        if (el.name === 'title') {
                            return <h5 key="title">{el.content.props.formData}</h5>;
                        }

                        return null;
                    })}
                </div>
            )}
            {expanded && (
                <div className="fieldWrapper">
                    {props.properties.map(el => {
                        if (!['type', 'settings', '_editor'].includes(el.name)) {
                            return el.content;
                        }

                        return null;
                    })}
                    <Button className="ok" onClick={() => setExpanded(false)} color="secondary">
                        {(props.uiSchema['ui:options'] && props.uiSchema['ui:options'].closeButtonLabel) || 'Lukk'}
                    </Button>
                </div>
            )}
        </div>
    );
};

const ArticleSettingsTemplate = props => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Accordion title="Innstillinger" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
            {props.map(el => {
                return el.content;
            })}
        </Accordion>
    );
};

const AnchorWidget = props => {
    const { formContext, id, value, onChange } = props,
        [anchorMode, setAnchorMode] = useState(false),
        [anchorId, setanchorId] = useState(value);

    const anchorHandler = () => {
        const changeHandler = id => {
            setanchorId(id);
        };

        if (!anchorMode) {
            formContext.onAnchorMode(id, value, changeHandler);
        } else {
            formContext.onAnchorMode(false);
        }

        setAnchorMode(!anchorMode);
    };

    useEffect(() => {
        if (anchorId !== value) {
            onChange(anchorId);
        }
    }, [anchorId, onChange, value]);

    return (
        <Fragment key={id}>
            {value}
            <p className="anchor_desc">
                {!anchorMode ? (
                    <Trans ns="dm.article" i18nKey="owner.headerblock.logoDesc.link_desc">
                        Klikk «Generer lenke» og deretter en innholdsblokk i forhåndsvisningen for å generere lenke:
                    </Trans>
                ) : (
                    <Trans ns="dm.article" i18nKey="owner.headerblock.logoDesc.link_descDone">
                        Klikk en innholdsblokk i forhåndsvisningen for å generere lenke, deretter «Ferdig»:
                    </Trans>
                )}
            </p>
            <Button className="link_btn" variant="contained" color="secondary" onClick={anchorHandler}>
                {anchorMode ? (
                    <Trans ns="dm.article" i18nKey="owner.headerblock.logoDesc.link_generateDone">
                        Ferdig
                    </Trans>
                ) : (
                    <Trans ns="dm.article" i18nKey="owner.headerblock.logoDesc.link_generate">
                        Generer lenke
                    </Trans>
                )}
            </Button>
        </Fragment>
    );
};

export const ArticleSettingsArrayFieldTemplate = props => {
    const classes = useStyles(),
        [tag, setTag] = useState();

    const onChange = ({ target: { value } }) => {
        setTag(value);
    };

    const addClick = value => {
        props.schema.items.properties.tag.default = value;
        setTag('');
        props.onAddClick();
    };

    return (
        <List className={classes.articleSettings}>
            <Grid item={true} xs>
                <Box mb={2}>
                    <Paper elevation={2}>
                        <Box p={2}>
                            {props.title && <h4>{props.title}</h4>}
                            {props.idSchema.tag ? (
                                <div className={classes.tagWrapper}>
                                    {props.items.map(el => {
                                        return <Chip key={`tag-chip-${el.index}`} label={el.children.props.formData.tag} onDelete={el.onDropIndexClick(el.index)} />;
                                    })}
                                </div>
                            ) : (
                                props.items.map(el => ArrayItemTemplate(el))
                            )}
                            {props.canAdd && props.idSchema.tag && (
                                <>
                                    <Input value={tag} onChange={onChange} />
                                    <IconButton
                                        aria-label={props.uiSchema['ui:options'].addButtonLabel}
                                        disabled={!tag || tag.length < 2}
                                        onClick={() => addClick(tag)}
                                        style={!tag || tag.length < 2 ? {} : { color: 'green' }}
                                        size="large"
                                    >
                                        <DoneIcon />
                                    </IconButton>
                                </>
                            )}
                        </Box>
                    </Paper>
                </Box>
            </Grid>
        </List>
    );
};

export const ArticleSettingsItemTemplate = props => {
    const { properties } = props;

    return (
        <>
            {props.title && <span key={props.title}>{props.title}</span>}
            {properties.map(el => {
                if (props.idSchema.$id.includes('tags')) {
                    if (el.name === 'tag') {
                        return el.content;
                    }
                }

                return null;
            })}
        </>
    );
};

export const AddressTemplate = props => {
    const { properties, formData } = props;

    const onChange = item => {
        properties.forEach(el => {
            const { content } = el,
                { props } = content;

            if (props && props.onChange && item[el.name]) {
                props.onChange(item[el.name]);
            }
        });
    };

    return PlaceQueryWidget(formData, onChange);
};

export const ReadOnlyValueFieldTemplate = props => {
    const theme = useTheme(),
        { ...mashupStyles } = useMashupStyles(theme),
        classes = useStyles(mashupStyles);

    return props.children.props.children.map(el => {
        return el && el.props.formData && <Chip key={el.props.formData} className={classes.chip} size="small" variant="outlined" label={el.props.formData} />;
    });
};

export const SearchTemplate = props => {
    const classes = useStyles();

    if (props.formData.type === 'DM') {
        return <ImageItemTemplate props={props.properties} classes={classes} type={props.formData.type} formContext={props.formContext} />;
    } else {
        return <DmSearchItemTemplate props={props} classes={classes} />;
    }
};

export const buildMediaListUiSchema = (themeColors = []) => {
    return {
        'ui:ArrayFieldTemplate': MediaArrayFieldTemplate,
        'ui:options': {
            addButtonLabel: (
                <Trans ns="dm.article" i18nKey="add_image">
                    Finn media
                </Trans>
            )
        },
        items: {
            'ui:ObjectFieldTemplate': ImageTemplate,
            'ui:options': {
                closeButtonLabel: (
                    <Trans ns="dm.common" i18nKey="close">
                        Lukk
                    </Trans>
                ),
                resetButtonLabel: (
                    <Trans ns="dm.common" i18nKey="reset">
                        Angre
                    </Trans>
                )
            },
            source: { 'ui:FieldTemplate': SvgThumbnailFieldTemplate },
            settings: {
                'ui:ObjectFieldTemplate': SettingsTemplate
            },
            filename: { 'ui:FieldTemplate': ReadOnlyValueFieldTemplate },
            licenses: {
                'ui:ArrayFieldTemplate': ArrayFieldTemplate,
                'ui:options': {
                    addButtonLabel: (
                        <Trans ns="dm.article" i18nKey="add_license">
                            Legg til lisens
                        </Trans>
                    )
                },
                items: {
                    'ui:ObjectFieldTemplate': NoWrapperTemplate
                }
            },
            bgColor: {
                'ui:options': {
                    complementary: true,
                    dominants: true,
                    theme: !!themeColors.length && {
                        colors: themeColors,
                        label: (
                            <Trans ns="dm.article" i18nKey="label_backgroundColor_fromTheme">
                                Velg bakgrunnsfarge fra tema:
                            </Trans>
                        )
                    }
                }
            }
        }
    };
};

export const buildUiSchema = themeColors => {
    const mediaListUiSchema = buildMediaListUiSchema(themeColors);

    return {
        'ui:submitButtonOptions': { norender: true },
        content: {
            'ui:ObjectFieldTemplate': ContentTemplate,
            page_type: {
                'ui:widget': 'hidden'
            },
            local_belonging: {
                'ui:ArrayFieldTemplate': ArticleSettingsArrayFieldTemplate,
                items: {
                    'ui:ObjectFieldTemplate': AddressTemplate
                },
                'ui:options': {
                    addable: false,
                    removable: false,
                    orderable: false
                }
            },
            tags: {
                'ui:ArrayFieldTemplate': ArticleSettingsArrayFieldTemplate,
                items: {
                    'ui:ObjectFieldTemplate': ArticleSettingsItemTemplate
                },
                'ui:options': {
                    addButtonLabel: (
                        <Trans ns="dm.article" i18nKey="add_topic">
                            Legg til nøkkelord
                        </Trans>
                    ),
                    orderable: false
                }
            },
            sections: {
                'ui:ArrayFieldTemplate': ArrayFieldTemplate,
                items: {
                    'ui:ObjectFieldTemplate': SectionTemplate,
                    _editor: {
                        'ui:ObjectFieldTemplate': NoWrapperTemplate,
                        _collapse: { 'ui:widget': 'hidden' }
                    },
                    type: { 'ui:FieldTemplate': NullTemplate },
                    blocks: {
                        'ui:ArrayFieldTemplate': BlockArrayFieldTemplate,
                        items: {
                            'ui:ObjectFieldTemplate': BlockTemplate,
                            _editor: {
                                'ui:ObjectFieldTemplate': NoWrapperTemplate,
                                _collapse: {
                                    'ui:widget': 'hidden'
                                }
                            },
                            _unique_key: { 'ui:FieldTemplate': UniqueKeyFieldTemplate },
                            settings: { 'ui:ObjectFieldTemplate': SettingsTemplate },
                            type: { 'ui:FieldTemplate': NullTemplate },
                            leadtext: {
                                'ui:widget': MarkdownWidget
                            },
                            'ui:options': {
                                addable: false,
                                removable: false
                            },
                            bodytext: {
                                'ui:widget': MarkdownWidget
                            },
                            maxListItems: {
                                'ui:widget': 'updown'
                            },
                            resources: {
                                'ui:ObjectFieldTemplate': NoWrapperTemplate,
                                mediaList: mediaListUiSchema,
                                backgroundImage: mediaListUiSchema,
                                entryList: {
                                    'ui:ArrayFieldTemplate': DmArrayFieldTemplate,
                                    'ui:options': {
                                        addButtonLabel: (
                                            <Trans ns="dm.article" i18nKey="add_object">
                                                Finn objekt
                                            </Trans>
                                        ),
                                        addSearchLabel: (
                                            <Trans ns="dm.article" i18nKey="add_search">
                                                Legg til søk
                                            </Trans>
                                        )
                                    },
                                    items: {
                                        'ui:ObjectFieldTemplate': SearchTemplate,
                                        objectId: { 'ui:FieldTemplate': ReadOnlyValueFieldTemplate },
                                        artifactType: { 'ui:FieldTemplate': ReadOnlyValueFieldTemplate },
                                        filter: {
                                            owner: { 'ui:widget': OwnerWidget }
                                        },
                                        settings: {
                                            'ui:ObjectFieldTemplate': SettingsTemplate
                                        }
                                    }
                                },
                                vmEntryList: {
                                    'ui:ArrayFieldTemplate': DmArrayFieldTemplate,
                                    'ui:FieldTemplate': NoWrapperFieldTemplate,
                                    'ui:options': {
                                        addButtonLabel: (
                                            <Trans ns="dm.article" i18nKey="add_vm_object">
                                                Finn Virtuelle Opplevelser
                                            </Trans>
                                        )
                                    },
                                    items: {
                                        'ui:ObjectFieldTemplate': SearchTemplate,
                                        objectId: {
                                            'ui:FieldTemplate': ReadOnlyValueFieldTemplate
                                        },
                                        artifactType: {
                                            'ui:FieldTemplate': ReadOnlyValueFieldTemplate
                                        },
                                        filter: {
                                            owner: { 'ui:widget': OwnerWidget }
                                        },
                                        settings: {
                                            'ui:ObjectFieldTemplate': SettingsTemplate
                                        },
                                        local: {
                                            title: {
                                                'ui:disabled': true
                                            },
                                            description: {
                                                'ui:disabled': true
                                            }
                                        }
                                    }
                                },
                                schoolEntryList: {
                                    'ui:ArrayFieldTemplate': SchoolArrayFieldTemplate,
                                    'ui:options': {
                                        addButtonLabel: (
                                            <Trans ns="dm.article" i18nKey="add_school_object">
                                                Finn skole objekt
                                            </Trans>
                                        )
                                    },
                                    items: {
                                        'ui:ObjectFieldTemplate': SearchTemplate,
                                        objectId: { 'ui:FieldTemplate': ReadOnlyValueFieldTemplate },
                                        artifactType: { 'ui:FieldTemplate': ReadOnlyValueFieldTemplate },
                                        filter: {
                                            owner: { 'ui:widget': OwnerWidget }
                                        },
                                        settings: {
                                            'ui:ObjectFieldTemplate': SettingsTemplate
                                        }
                                    }
                                },
                                logo: {
                                    'ui:ArrayFieldTemplate': MediaArrayFieldTemplate,
                                    'ui:options': {
                                        addButtonLabel: (
                                            <Trans ns="dm.article" i18nKey="owner.headerBlock.add_logo">
                                                Finn logo
                                            </Trans>
                                        )
                                    },
                                    items: {
                                        'ui:ObjectFieldTemplate': ImageTemplate,
                                        licenses: mediaListUiSchema.items.licenses,
                                        source: { 'ui:FieldTemplate': SvgThumbnailFieldTemplate }
                                    }
                                },
                                linkList: {
                                    'ui:ArrayFieldTemplate': LinkArrayFieldTemplate,
                                    'ui:options': {
                                        addInternalLinkButtonLabel: (
                                            <Trans ns="dm.article" i18nKey="owner.headerBlock.add_internalLink">
                                                Lenke til innhold på siden
                                            </Trans>
                                        ),
                                        addLinkButtonLabel: (
                                            <Trans ns="dm.article" i18nKey="owner.headerBlock.add_link">
                                                Legg til URL eller epost
                                            </Trans>
                                        )
                                    },
                                    items: {
                                        'ui:ObjectFieldTemplate': LinkItemTemplate,
                                        'ui:options': {
                                            closeButtonLabel: (
                                                <Trans ns="dm.common" i18nKey="close">
                                                    Lukk
                                                </Trans>
                                            )
                                        },
                                        type: { 'ui:widget': 'hidden' },
                                        _unique_key: { 'ui:widget': AnchorWidget }
                                    }
                                }
                            },
                            presentation: {
                                'ui:widget': 'textarea'
                            }
                        }
                    }
                },
                'ui:options': {
                    addable: false,
                    removable: false,
                    orderable: false
                }
            }
        }
    };
};
