import { Card } from './Card/Card';

customElements.define(
    'kit-vm-card',
    class VmCard extends HTMLElement {
        onClick = () => {
            if (this.href) {
                window.location.href = this.href;
            }
        };

        connectedCallback() {
            console.log('KIT-VmCard @connectedCallback');

            this.width = `${this.getAttribute('width') || 352}px`;
            this.height = `${this.getAttribute('height') || 456}px`;
            this.backgroundColor = this.getAttribute('data-backgroundColor') || '#82ABA0';
            this.imgSrc = this.getAttribute('data-imgSrc');
            this.museum = this.getAttribute('data-museum');
            this.tags = this.getAttribute('data-tags').split(',');
            this.buttonLabel = this.getAttribute('data-buttonLabel') || 'Opplev utstillingen';
            this.paragraph = this.getAttribute('data-paragraph');
            this.href = this.getAttribute('data-href');

            this.ready();
        }

        ready = () => {
            this.appendChild(
                Card({
                    width: this.width,
                    height: this.height,
                    backgroundColor: this.backgroundColor,
                    imgSrc: this.imgSrc,
                    title: this.title,
                    museum: this.museum,
                    tags: this.tags,
                    buttonLabel: this.buttonLabel,
                    paragraph: this.paragraph,
                    onClick: this.onClick
                })
            );
        };
    }
);
