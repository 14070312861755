import React from 'react';
import { useLocation } from 'react-router-dom';

import { StatisticsReportDownloads, StatisticsReportMetricsTable } from './plausible';

export const StatisticsFinder = () => {
    const location = useLocation();

    return (
        location.pathname === '/statistics/downloads'
            ? (
                <StatisticsReportDownloads viewType='report' />
            ) : (
                <div className='admin-statistics-wrapper'>
                    <StatisticsReportMetricsTable viewType='report' />
                </div>
            )
    );
};
