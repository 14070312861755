import { Box } from '@mui/material';

import '@web-components/virtueltmuseum';

import './VirtualExperience.module.css';

const VmCardComponent = ({ id, size, imageColor, imgSrc, title, museum, tags, paragraph, href }) => (
    <Box key={`box-${id}`} sx={{ margin: '8px 0' }}>
        <kit-vm-card
            key={id}
            className={size}
            title={title}
            data-backgroundColor={imageColor}
            data-imgSrc={imgSrc}
            data-museum={museum}
            data-tags={tags}
            data-paragraph={paragraph}
            data-href={href}
        ></kit-vm-card>
    </Box>
);

VmCardComponent.displayName = 'VmCardComponent';

export const VirtualExperience = ({ block }) => {
    const { resources } = block;

    return (
        <section className="section section--search-vm">
            <Box className="vm-wrapper">
                {resources?.vmEntryList?.map(item => (
                    <VmCardComponent
                        key={`kit-vm-card-p-${item?.objectId}`}
                        id={`kit-vm-card-${item?.objectId}`}
                        size={item?.settings?.size}
                        imgColor={item?.image_color}
                        imgSrc={item.url}
                        title={item?.title}
                        museum={item.owner_name}
                        tags=""
                        paragraph={item?.local?.description}
                        href=""
                    />
                ))}
            </Box>
        </section>
    );
};
